import { apiDelete, apiGet, apiPatch, apiPost } from "./_server";
import { UpdatePasswordRequest, UpdateProfileRequest, User } from "./models/user";

export async function getProfile(): Promise<User> {
  return apiGet({ path: "me" });
}

export async function updateProfile(payload: UpdateProfileRequest): Promise<User> {
  return apiPatch({ path: "me", data: payload });
}

export async function deleteAccount(password: string): Promise<void> {
  return apiDelete({ path: "me", data: { password } });
}

export async function updatePassword(payload: UpdatePasswordRequest): Promise<void> {
  return apiPatch({ path: "me/password", data: payload });
}

export async function requestEmailConfirmationCode(): Promise<void> {
  return apiPost({ path: "me/request_email_confirmation_code" });
}

export async function validateEmailConfirmationCode(emailConfirmationCode: string): Promise<void> {
  return apiPost({
    path: "me/validate_email_confirmation_code",
    data: { emailConfirmationCode },
  });
}

export async function updateViewModeWarningStatus(showWarning: boolean): Promise<void> {
  return apiPatch({ path: "me/view_mode_switch_warning", data: { showWarning } });
}

export async function turnOffOnboarding(): Promise<void> {
  return apiPatch({ path: "me/onboarding" });
}
