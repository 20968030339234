import { BaseEntity } from "./baseEntity";
import { Company } from "./company";
import { Group } from "./group";
import { Language } from "./language";
import { ScheduledAction, ScheduledActionPayload } from "./scheduled_action";

// Request

export interface CreateRequest {
  email: string;
  name: string;
  role: UserRole;
  status: UserStatus;
  phoneNumber?: string | null;
  jobPosition?: string | null;
  profilePictureUrl?: string | null;
  groupsIds?: string[] | null;
  invitationScheduling?: ScheduledActionPayload;
}

export interface UpdateRequest {
  phoneNumber?: string | null;
  jobPosition?: string | null;
  profilePictureUrl?: string | null;
  groupsIds?: string[] | null;
  email?: string;
  name?: string;
  role?: UserRole;
  countryCodeId?: string | null;
}

export interface UpdateProfileRequest {
  email?: string;
  name?: string;
  phoneNumber?: string | null;
  jobPosition?: string | null;
  profilePictureUrl?: string | null;
  languagesIds?: string[];
  countryCodeId?: string | null;
}

export interface ImportUsersPayload {
  invitationScheduling?: ScheduledActionPayload;
  sheetUrl: string;
}

export interface ImportUsersResponse {
  successfulInvitesUsersEmails: string[];
  failedInvitesUsers: { email: string; errorMessages: string[] }[];
}

export interface UpdatePasswordRequest {
  currentPassword: string;
  password: string;
}

// Response
export enum UserRole {
  USER = "USER",
  ADMINISTRATOR = "ADMINISTRATOR",
  SUPER_ADMINISTRATOR = "SUPER_ADMINISTRATOR",
  SUPPORT = "SUPPORT",
  PLATFORM_ADMINISTRATOR = "PLATFORM_ADMINISTRATOR",
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  INVITE_SENT = "INVITE_SENT",
  INVITE_SCHEDULED = "INVITE_SCHEDULED",
  INVITE_CANCELLED = "INVITE_CANCELLED",
  DRAFT = "DRAFT",
}

export enum UserTableKind {
  ACTIVE = "ACTIVE",
  INVITES = "INVITES",
  INACTIVE = "INACTIVE",
}

export const UserStatusTranslated = {
  [UserStatus.INVITE_SENT]: "Sent",
  [UserStatus.ACTIVE]: "Active",
  [UserStatus.INACTIVE]: "Inactive",
  [UserStatus.DRAFT]: "Draft",
  [UserStatus.INVITE_CANCELLED]: "Canceled",
  [UserStatus.INVITE_SCHEDULED]: "Scheduled",
};

export const UserRoleTranslated = {
  [UserRole.USER]: "User",
  [UserRole.ADMINISTRATOR]: "Admin",
  [UserRole.SUPER_ADMINISTRATOR]: "Super Admin",
  [UserRole.SUPPORT]: "Kala support",
  [UserRole.PLATFORM_ADMINISTRATOR]: "Platform admin",
};

export enum Permission {
  USERS = "USERS",
  DASH = "DASH",
  BLOG = "BLOG",
}

export const PermissionTranslated = {
  [Permission.USERS]: "Usuários",
  [Permission.DASH]: "Dashboard",
  [Permission.BLOG]: "Blog",
};

export interface User extends BaseEntity {
  role: UserRole;
  roleTranslated: string;
  permissions: Permission[] | null;
  permissionsTranslated: string[] | null;
  name: string;
  email: string;
  jobPosition?: string | null;
  phoneNumber?: string | null;
  profilePictureUrl: string | null;
  status: UserStatus;
  company: Company | null;
  groups: Group[];
  groupsIds?: string[];
  languages?: Language[];
  countryCodeId?: string;
  languagesIds?: string[];
  personalRootFolderId: string;
  companyRootFolderId: string;
  isSelected?: boolean;
  showViewModeSwitchWarning: boolean;
  isTemporary?: boolean;
  showOnboarding?: boolean;
  invitationScheduling?: ScheduledAction;
}

export function getTranslatedRole(role: UserRole, fullTranslation = false): string {
  const adminWord = fullTranslation ? "Administrator" : "Admin";

  return {
    [UserRole.USER]: "User",
    [UserRole.ADMINISTRATOR]: adminWord,
    [UserRole.SUPER_ADMINISTRATOR]: `Super ${adminWord}`,
    [UserRole.SUPPORT]: `Kala Support`,
    [UserRole.PLATFORM_ADMINISTRATOR]: `Platform ${adminWord}`,
  }[role];
}
