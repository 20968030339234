import { Axios } from "axios";

import { apiGet } from "../api/_server";
import { UploadResponse, UploadUrlResponse } from "../api/models/upload";

export interface ResumableFileUploaderOptions {
  chunkSize?: number;
  onUploadProgress?: (progress: number) => void;
}

export class ResumableFileUploader {
  constructor(readonly file: File, readonly options: ResumableFileUploaderOptions = {}) {
    this._chunkSize = options.chunkSize ?? this._chunkSize;
  }

  private readonly _chunkSize = 256 * 1024 * 400; // ~100 MiB
  private readonly _httpClient = new Axios({});
  private readonly _abortController = new AbortController();
  private readonly _worker = new Worker("/fileReaderWorker.js");

  private _getChunkBytes(chunk: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      this._worker.onmessage = ({ data }) => resolve(data);
      this._worker.onerror = reject;
      this._worker.postMessage(chunk);
    });
  }

  private async _uploadChunks(uploadUrl: string): Promise<void> {
    for (let chunkPosition = 0; chunkPosition < this.file.size; chunkPosition += this._chunkSize) {
      const chunk = this.file.slice(chunkPosition, chunkPosition + this._chunkSize);
      const chunkBytes = await this._getChunkBytes(chunk);

      await this._httpClient.put(uploadUrl, chunkBytes, {
        headers: {
          "Content-Range": `bytes ${chunkPosition}-${chunkPosition + chunkBytes.byteLength - 1}/${
            this.file.size
          }`,
        },
        onUploadProgress: ({ loaded }) => {
          const progress = (chunkPosition + loaded) / this.file.size;
          this.options.onUploadProgress?.(progress);
        },
        signal: this._abortController.signal,
      });
    }
  }
  abort(): void {
    this._abortController.abort();
  }

  async upload(): Promise<UploadResponse> {
    const { uploadUrl: uploadCreationUrl, fileName }: UploadUrlResponse = await apiGet({
      path: "/upload/url",
      config: { params: { fileName: this.file.name } },
    });

    const { headers } = await this._httpClient.post(
      uploadCreationUrl,
      {},
      { headers: { "x-goog-resumable": "start", "Content-Type": this.file.type } }
    );

    await this._uploadChunks(headers.location);

    this._worker.terminate();

    return { fileName };
  }
}
