import { AxiosProgressEvent } from "axios";
import * as XLSX from "xlsx";

import axios from "../axios";

export function getFileNameFromUrl(url: string) {
  const fileExtension = url.match(/[^\\/]+?(?=\.\w+$)/gi);

  return fileExtension ? fileExtension[0] : "";
}

export function getFileExtensionFromFileName(fileName: string): string {
  const matches = fileName.match(/\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gi);

  return matches ? matches[0] : "";
}

export async function getFileFromUrl(
  url: string,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
): Promise<File> {
  const { data: file } = await axios({
    url,
    method: "GET",
    responseType: "blob",
    onDownloadProgress: onDownloadProgress,
  });

  return file;
}

export async function downloadFileFromUrl(
  url: string,
  fileName?: string,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
) {
  const file = await getFileFromUrl(url, onDownloadProgress);

  const _fileName = (fileName ?? getFileNameFromUrl(url))
    .normalize("NFD")
    .replace(/[^-A-Za-z0-9\s]/g, "")
    .replaceAll(" ", "_");

  const fileExtension = getFileExtensionFromFileName(url);

  const fileURL = window.URL.createObjectURL(new Blob([file]));
  const fileLink = document.createElement("a");
  fileLink.href = fileURL;
  fileLink.setAttribute("download", `${_fileName}${fileExtension}`);
  document.body.appendChild(fileLink);
  fileLink.click();
  document.body.removeChild(fileLink);
}

export function downloadCsv(data: string, fileName: string) {
  const a = document.createElement("a");
  document.body.appendChild(a);
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = `${fileName}.csv`;
  a.click();
  window.URL.revokeObjectURL(url);
}

export function downloadCsvToXlsx(data: string, fileName: string) {
  const headers = String(data).split(",");
  const ws = XLSX.utils.json_to_sheet([], { header: headers });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, fileName);
  /* generate XLSX file and send to client */
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}

export function resizeImageFileToSquare(
  file: File,
  imageSize = undefined,
  format = "image/png",
  quality = 1
): Promise<File> {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      if (img.width === img.height) {
        resolve(file);
      }

      const imgSize = imageSize ?? Math.max(img.width, img.height);
      const canvas = document.createElement("canvas");
      canvas.width = imgSize;
      canvas.height = imgSize;
      const context = canvas.getContext("2d");

      context?.clearRect(0, 0, canvas.width, canvas.height);

      let newWidth, newHeight, offsetX, offsetY;

      if (img.width > img.height) {
        newWidth = imgSize;
        newHeight = (imgSize / img.width) * img.height;
        offsetX = 0;
        offsetY = (imgSize - newHeight) / 2;
      } else {
        newHeight = imgSize;
        newWidth = (imgSize / img.height) * img.width;
        offsetY = 0;
        offsetX = (imgSize - newWidth) / 2;
      }

      context?.drawImage(img, offsetX, offsetY, newWidth, newHeight);

      canvas.toBlob(
        (resizedBlob) => {
          if (resizedBlob) {
            const fileName = file.name; // Use o nome original do arquivo
            const fileType = format;
            const resizedFile = new File([resizedBlob], fileName, { type: fileType });
            resolve(resizedFile);
          } else {
            reject(new Error("Error on resize, try again"));
          }
        },
        format,
        quality
      );
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = URL.createObjectURL(file);
  });
}
