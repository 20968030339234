<template>
  <button
    :type="type"
    :disabled="disabled || isLoading"
    :class="[
      classes.base,
      classes.variant[variant],
      {
        [classes.disabledBase]: disabled,
        [classes.disabled[variant]]: disabled,
        [classes.hover[variant]]: !disabled && !isLoading,
      },
    ]"
    @mouseleave="isHovering = false"
    @mouseover="isHovering = true"
    @click.prevent="emit('click', $event)"
  >
    <LoadingSpinner
      v-if="isLoading"
      :color="classes.loadingColor[props.variant]"
      :size="loadingSize"
      :stroke="loadingStroke"
    />

    <div v-else v-tooltip="hoverMessage" class="flex items-center">
      <Icon
        v-if="iconOnLeftSide"
        :class="['mr-2 !transition-all', iconClass, isHovering ? iconHoverColor : '']"
        :name="iconOnLeftSide"
        :size="iconSize"
      />
      <slot />
      <Icon
        v-if="iconOnRightSide"
        :class="['ml-2 !transition-all', iconClass, isHovering ? iconHoverColor : '']"
        :name="iconOnRightSide"
        :size="iconSize"
      />
    </div>
  </button>
</template>

<script setup lang="ts">
import { ref } from "vue";

import Icon, { IconName } from "./Icon.vue";
import LoadingSpinner, { ColorsName } from "./LoadingSpinner.vue";

export type ButtonVariant =
  | "elevated"
  | "elevatedShadow"
  | "outlined"
  | "outlinedDestructive"
  | "outlinedLight"
  | "dashed"
  | "text";

export type ButtonProps = {
  type?: "submit" | "button" | "reset";
  variant?: ButtonVariant;
  disabled?: boolean;
  isLoading?: boolean;
  loadingSize?: number;
  loadingStroke?: number;
  loadingColor?: ColorsName;
  iconOnRightSide?: IconName;
  iconOnLeftSide?: IconName;
  iconSize?: string;
  iconClass?: string;
  iconHoverColor?: string;
  hoverMessage?: string;
};

const props = withDefaults(defineProps<ButtonProps>(), {
  type: "submit",
  variant: "elevated",
  disabled: false,
  isLoading: false,
  loadingSize: 20,
  loadingStroke: 3,
  loadingColor: undefined,
  iconOnRightSide: undefined,
  iconOnLeftSide: undefined,
  iconSize: undefined,
  iconClass: undefined,
  iconHoverColor: undefined,
  hoverMessage: undefined,
});

interface Classes {
  base: string;
  disabledBase: string;
  variant: { [Key in ButtonVariant]: string };
  hover: { [Key in ButtonVariant]: string };
  disabled: { [Key in ButtonVariant]: string };
  loadingColor: { [Key in ButtonVariant]: ColorsName };
}

const isHovering = ref(false);

const classes: Classes = {
  base: "flex items-center justify-center transition-all ease-out duration-200 min-h-button w-full max-w-button px-2 py-3",
  disabledBase: "opacity-50 cursor-not-allowed",
  variant: {
    elevated: "elevated !bg-primary !bg-[length:200%] text-white rounded-sm",
    elevatedShadow:
      "shadow-primary bg-white text-primary border-2 rounded-full border-white !py-2 !px-4",
    outlined: "border-2 bg-white border-button-border-secondary text-primary rounded-sm",
    outlinedLight: "border-2 border-white text-white rounded-sm",
    outlinedDestructive: "text-error rounded-sm",
    dashed: "border-2 border-dashed border-primary text-primary rounded-sm",
    text: "text-primary w-fit h-fit border-b-input-width border-b-transparent !py-0 min-h-fit transition-colors",
  },
  hover: {
    elevated: "hover:bg-left",
    elevatedShadow: "hover:border-primary",
    outlined: "hover:bg-tertiary hover:border-primary",
    outlinedLight: "hover:bg-white hover:text-primary",
    outlinedDestructive: "hover:bg-error-light",
    dashed: "hover:opacity-75",
    text: "hover:border-b-primary",
  },
  disabled: {
    elevated: "",
    elevatedShadow: "",
    outlined: "",
    outlinedLight: "",
    outlinedDestructive: "",
    dashed: "",
    text: "",
  },
  loadingColor: {
    elevated: props.loadingColor ?? "white",
    elevatedShadow: props.loadingColor ?? "primary",
    outlined: props.loadingColor ?? "primary",
    outlinedLight: props.loadingColor ?? "white",
    dashed: props.loadingColor ?? "primary",
    text: props.loadingColor ?? "primary",
    outlinedDestructive: props.loadingColor ?? "error",
  },
};

const emit = defineEmits<{ (event: "click", value?: Event): void }>();
</script>
<style scoped>
.elevated {
  background: linear-gradient(to left, var(--primary) 50%, rgba(0, 0, 0, 0.33) 50%) right;
}
</style>
