import _ from "lodash";
import { defineStore } from "pinia";

import { contentCrud } from "../api/content";
import { contentFileVersionCrud } from "../api/contentFileVersion";
import {
  Content,
  CreateContentLocal,
  CreateContentStatus,
  UploadFileStatus,
} from "../api/models/content";
import { getPayload } from "../utils";
import { AppError, ContentError } from "../utils/error";
import { ResumableFileUploader } from "../utils/resumable_file_uploader";

import { useUserStore } from "./user";

export const useUploadContentStore = defineStore({
  id: "uploadContent",
  state: () => {
    return {
      isLoading: false,
      showIconOnUploadButton: false,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      contents: <CreateContentLocal[]>undefined!,
    };
  },
  actions: {
    tryUploadContentAgain(contentId: string, version: number): void {
      const content = this.getByIdAndVersionOrFail(contentId, version);
      this.createContent(content);
    },
    hideUploadButtonIcon(): void {
      this.showIconOnUploadButton = false;
    },
    async loadRecentUploads() {
      const userStore = useUserStore();
      if (!this.contents && userStore.isActiveCompanyUser) {
        this.contents = await contentCrud.getUserRecentUploads();
        this.contents.forEach((content) => {
          content.uploadFileStatus = UploadFileStatus.UPLOADED;
          content.createContentStatus = CreateContentStatus.CREATED;
        });
      }

      return this.contents;
    },
    async upload(
      content: CreateContentLocal,
      options: { isNewVersion?: boolean; isCreate: boolean }
    ) {
      this.showIconOnUploadButton = true;

      this.upsert({ ...content, updatedDate: new Date() });
      this.setCreatingStatus(content.id, content.version);

      try {
        if (
          ![UploadFileStatus.UPLOADED, UploadFileStatus.UPLOADING].includes(
            content.uploadFileStatus
          )
        ) {
          await this.uploadStorageFile(content.id, content.fileUrl as File, content.version);
        }
        await this.createOrUpdate(content, options);
      } catch (error) {
        if (error instanceof ContentError) {
          const { contentId, type, message } = error;

          const _content = this.getByIdAndVersionOrFail(contentId);
          _content.errorMessage = message;

          if (type === "upload") {
            _content.uploadFileStatus = UploadFileStatus.ERROR;
          }
          _content.createContentStatus = CreateContentStatus.ERROR;
        }
      } finally {
        this.showIconOnUploadButton = true;
      }
    },
    async updateContentVersion(content: CreateContentLocal, isNewVersion: boolean): Promise<void> {
      const newContent = _.cloneDeep(content);

      await this.upload(newContent, { isCreate: false, isNewVersion });
    },
    async createContent(content: CreateContentLocal): Promise<void> {
      await this.upload(content, { isCreate: true });
    },
    deleteContentsByIds(contentIds: string[]): void {
      const remainingContent = this.contents.filter(({ id }) => !contentIds.includes(id));

      this.contents.splice(0, this.contents.length, ...remainingContent);
    },
    addOrUpdateLocalContent(content: CreateContentLocal): void {
      this.upsert(content);
      this.showIconOnUploadButton = true;
    },
    cancelUpload(content: CreateContentLocal): void {
      if (content.uploadFileStatus !== UploadFileStatus.UPLOADING || !content.cancelUpload) {
        return;
      }
      content.cancelUpload();
      this.updateUploadProgress(content.id, content.version, undefined);
    },
    async uploadStorageFile(contentId: string, localFile: File, version = 1): Promise<void> {
      this.setUploadingStatus(contentId, version);
      if (!localFile) {
        throw new ContentError(
          contentId,
          "create",
          "Content file not found, please try selecting it again"
        );
      }
      try {
        const uploader = new ResumableFileUploader(localFile, {
          onUploadProgress: (progress) => this.updateUploadProgress(contentId, version, progress),
        });
        const content = this.getByIdAndVersionOrFail(contentId, version);

        content.cancelUpload = uploader.abort;

        const { fileName } = await uploader.upload();
        this.setUploadSuccessStatus(contentId, version, fileName);
      } catch (error) {
        throw new ContentError(contentId, "upload", error);
      }
    },
    async createOrUpdate(
      contentData: CreateContentLocal,
      { isNewVersion, isCreate }: { isNewVersion?: boolean; isCreate: boolean }
    ) {
      const content = this.getByIdAndVersionOrFail(contentData.id, contentData.version);

      if (content.uploadFileStatus !== UploadFileStatus.UPLOADED) {
        throw new ContentError(contentData.id, "create");
      }
      try {
        this.setCreatingStatus(contentData.id, contentData.version);

        let response: Content;

        if (isCreate) {
          const payload = getPayload(content, "CREATE");
          response = await contentCrud.create(payload);
        } else {
          response = await contentFileVersionCrud.updateContentFile(contentData.id, {
            fileUrl: <any>content.fileUrl,
            isNewVersion: !!isNewVersion,
          });
        }
        this.setCreationSuccessStatus(contentData.id, contentData.version, response);
      } catch (error) {
        throw new ContentError(contentData.id, "create", error);
      }
    },

    getByIdAndVersionOrFail(contentId: string, version = 1): CreateContentLocal {
      const content = this.contents.find(
        ({ id, version: contentVersion }) => id === contentId && contentVersion === version
      );

      if (!content) {
        throw new AppError("Content not found");
      }

      return content;
    },

    getIndexByIdAndVersion(contentId: string, version = 1): number {
      return this.contents.findIndex(
        ({ id, version: contentVersion }) => id === contentId && contentVersion === version
      );
    },

    upsert(content: CreateContentLocal): void {
      const contentIndex = this.getIndexByIdAndVersion(content.id, content.version);

      if (contentIndex >= 0) {
        Object.assign(this.contents[contentIndex], content);
      } else {
        this.contents.unshift(content);

        const limit = 5;

        if (this.contents.length > limit) {
          this.contents.splice(limit - 1);
        }
      }

      this.showIconOnUploadButton = true;
    },

    delete(contentId: string, version = 1): void {
      const contentIndex = this.getIndexByIdAndVersion(contentId, version);

      if (contentIndex >= 0) {
        this.contents.splice(contentIndex, 1);
      }
    },

    setCreatingStatus(contentId: string, version = 1): void {
      const content = this.getByIdAndVersionOrFail(contentId, version);
      content.createContentStatus = CreateContentStatus.CREATING;
    },

    setUploadingStatus(contentId: string, version = 1): void {
      const content = this.getByIdAndVersionOrFail(contentId, version);
      content.uploadFileStatus = UploadFileStatus.UPLOADING;
    },

    setUploadSuccessStatus(contentId: string, version: number, fileName: string): void {
      const content = this.getByIdAndVersionOrFail(contentId, version);

      content.fileUrl = fileName;
      content.errorMessage = null;
      content.uploadFileStatus = UploadFileStatus.UPLOADED;
    },

    updateUploadProgress(contentId: string, version: number, progress: number | undefined): void {
      const content = this.getByIdAndVersionOrFail(contentId, version);
      content.progress = progress;
    },

    setCreationSuccessStatus(contentId: string, version: number, createdContent: Content): void {
      const content = this.getByIdAndVersionOrFail(contentId, version);

      Object.assign(content, {
        ...createdContent,
        createContentStatus: CreateContentStatus.CREATED,
        errorMessage: null,
        coverImageLocalSrcUrl: null,
      });
    },
  },
});
