import { DirectiveBinding } from "vue";

// Para utilizar o tooltip
// Adicione v-tooltip em um elemento passando a string a ser exibida
// Ex: <p v-tooltip="'Texto de exemplo'">Informações</p>

function createTooltipElement(text: string): HTMLSpanElement {
  const tooltip = document.createElement("span"); // Cria o elemento do tooltip
  tooltip.className = "tooltip-item"; // Adiciona a classe tooltip-item ao componente
  tooltip.textContent = text;

  return tooltip;
}

const tooltipDirective = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const tooltipText = binding.value;
    if (!tooltipText) {
      return;
    }

    const tooltipElement = createTooltipElement(tooltipText);

    // Exibe tooltip ao passar mouse
    el.addEventListener("mouseenter", () => {
      showTooltip(el, tooltipElement);
    });

    // Remove tooltip ao remover mouse
    el.addEventListener("mouseleave", () => {
      closeTooltip(tooltipElement);
    });

    // Remove tooltip ao scrollar tela
    // Necessário para não quebrar layout fixed
    window.addEventListener("scroll", () => {
      closeTooltip(tooltipElement);
    });
  },
};

function showTooltip(el: HTMLElement, tooltipElement: HTMLSpanElement) {
  const rect = el.getBoundingClientRect();

  const siblingWidth = el.offsetWidth; // Largura do elemento irmão
  tooltipElement.style.top = `${rect.top - 35}px`;
  tooltipElement.style.left = `${rect.left + siblingWidth / 2}px`; // Centralizar horizontalmente
  tooltipElement.style.transform = "translateX(-50%)"; // Centralizar horizontalmente
  tooltipElement.style.position = "fixed";

  document.body.appendChild(tooltipElement);
}

function closeTooltip(tooltipElement: HTMLSpanElement) {
  if (tooltipElement.parentNode) {
    tooltipElement.parentNode.removeChild(tooltipElement);
  }
}

export default tooltipDirective;
