import _ from "lodash";

import { BaseEntity } from "./baseEntity";
import { ContentFileVersion } from "./contentFileVersion";
import { Folder, ViewModeEnum } from "./folder";
import { Language } from "./language";
import { ScheduledAction, ScheduledActionPayload } from "./scheduled_action";
import { User } from "./user";

export interface Content extends BaseEntity {
  title: string;
  fileUrl: string;
  description: string | null;
  coverImageUrl: string | null;
  isRecommended: boolean;
  isConfidential: boolean;
  sizeInBytes: number;
  language: Language | null;
  folders?: Folder[] | null;
  fileExtension: string;
  user?: User;
  isPersonal?: boolean;
  isHidden?: boolean;
  type: ContentTypeEnum;
  librariesQuantity?: number;
  rating?: number;
  currentVersion?: number;
  userRating?: { feedback: string | null; rating: number | null };
  expirationScheduling?: (ScheduledActionPayload & Partial<ScheduledAction>) | null;
  versionsDates?: ContentFileVersion[];
  lastMajorVersion?: number;
  isInTrashBin?: boolean;
  deletedDate?: Date;
  deletedBy?: User;
  version?: number;
  isDownloadable?: boolean;
}

export interface CreatePersonalContentDto {
  title: string;
  fileUrl: string;
  description?: string | null;
  coverImageUrl?: string | null;
  foldersIds?: string[] | null;
  languageId?: string | null;
}

export interface CreateCompanyContentDto extends CreatePersonalContentDto {
  isRecommended: boolean;
  isConfidential: boolean;
  expirationScheduling?: (ScheduledActionPayload & Partial<ScheduledAction>) | null;
}

export interface ContentRecentUploadDto {
  id: string;
  updatedDate: Date;
  title: string;
  coverImageUrl?: string | null;
  version: number;
  fileExtension: string;
}

export interface CreateContentLocal extends ContentRecentUploadDto {
  coverImageLocalSrcUrl: string | null;
  createdDate?: Date;
  fileName: string;
  fileUrl: File | string;
  createContentStatus: CreateContentStatus;
  uploadFileStatus: UploadFileStatus;
  description: string;
  languageId?: string | null;
  isRecommended: boolean;
  isConfidential: boolean;
  foldersIds?: string[];
  expirationScheduling?: (ScheduledActionPayload & Partial<ScheduledAction>) | null;
  progress?: number;
  cancelUpload?: () => void;
  errorMessage?: null | string;
  disableAutofillingCoverImage?: boolean;
}

export interface AddContentsToFoldersDto {
  contentsIds: string[];
  foldersIds: string[];
}

export interface UpdateContentFileDto {
  fileUrl: string;
  isNewVersion: boolean;
}

export interface ContentFilterDto {
  limit?: number;
  initialDate?: Date;
  finalDate?: Date;
  type?: ContentTypeEnum;
  foldersIds?: string[];
  languagesIds?: string[];
  isRecommended?: boolean;
  isConfidential?: boolean;
  viewMode?: ViewModeEnum;
  groupsIds?: string[];
}

export interface ContentVersionResponseDto {
  id: string;
  createdDate: Date;
  version: number;
  fileUrl: string;
  author: User;
  sizeInBytes: number;
  size: string;
  type: ContentTypeEnum;
}

export interface FlatContentVersionResponseDto {
  id: string;
  createdDate: Date;
  version: number;
  fileUrl: string;
  "author.id": string;
  "author.name": string;
  "author.profilePictureUrl": string;
  sizeInBytes: number;
  size: string;
  type: ContentTypeEnum;
}

export enum ContentTypeEnum {
  PRESENTATION = "PRESENTATION",
  VIDEO = "VIDEO",
  IMAGE = "IMAGE",
  DOCUMENT = "DOCUMENT",
  AUDIO = "AUDIO",
  SPREADSHEET = "SPREADSHEET",
  OTHER = "OTHER",
}

export const ContentTypeExtensions: { [key in ContentTypeEnum]: string[] } = {
  [ContentTypeEnum.AUDIO]: ["mp3", "wav"],
  [ContentTypeEnum.VIDEO]: ["mp4", "avi", "wmv", "mov"],
  [ContentTypeEnum.IMAGE]: [
    "jpg",
    "jpeg",
    "png",
    "jfif",
    "bmp",
    "gif",
    "tif",
    "tiff",
    "webp",
    "raw",
  ],
  [ContentTypeEnum.PRESENTATION]: ["ppt", "pptx", "pptm"],
  [ContentTypeEnum.SPREADSHEET]: ["csv", "xls", "xlsx", "tsv"],
  [ContentTypeEnum.DOCUMENT]: ["pdf", "doc", "txt", "docx", "rtf"],
  [ContentTypeEnum.OTHER]: [],
};

export function getContentTypeEnumText(type: ContentTypeEnum): string {
  const extensions = ContentTypeExtensions[type];

  let extensionsText = "";
  if (extensions.length) {
    extensionsText = ` (.${extensions.join(", .")})`;
  }

  return `${_.capitalize(type)}${extensionsText}`;
}

export enum CreateContentStatus {
  PENDING = "PENDING",
  CREATING = "CREATING",
  CREATED = "CREATED",
  ERROR = "ERROR",
}

export enum UploadFileStatus {
  PENDING = "PENDING",
  UPLOADING = "UPLOADING",
  UPLOADED = "UPLOADED",
  ERROR = "ERROR",
}

export const inBrowserSupportedExtensionsByType: Record<ContentTypeEnum, string[]> = {
  [ContentTypeEnum.AUDIO]: ["mp3", "wav"],
  [ContentTypeEnum.VIDEO]: ["mp4", "ogg", "webm", "mov"],
  [ContentTypeEnum.IMAGE]: ["jpg", "jpeg", "png", "jfif", "gif", "webp"],
  [ContentTypeEnum.DOCUMENT]: ["pdf", "docx", "doc", "txt"],
  [ContentTypeEnum.PRESENTATION]: ["pptx", "ppt"],
  [ContentTypeEnum.SPREADSHEET]: ["xlsx", "xls"],
  [ContentTypeEnum.OTHER]: [],
};

export function getContentTypeByExtension(extension: string): ContentTypeEnum {
  for (const contentType in inBrowserSupportedExtensionsByType) {
    if (
      inBrowserSupportedExtensionsByType[contentType as ContentTypeEnum].includes(
        extension.toLowerCase()
      )
    ) {
      return contentType as ContentTypeEnum;
    }
  }

  return ContentTypeEnum.OTHER; // Se a extensão não for encontrada em nenhum grupo
}

export function isContentViewable(type: ContentTypeEnum, fileExtension: string): boolean {
  return inBrowserSupportedExtensionsByType[type].includes(fileExtension.toLocaleLowerCase());
}

export const forbiddenFileExtensions = [
  "apk",
  "app",
  "bat",
  "bin",
  "cgi",
  "class",
  "cmd",
  "com",
  "deb",
  "dmg",
  "dll",
  "elf",
  "exe",
  "gadget",
  "ipa",
  "jar",
  "msi",
  "out",
  "pl",
  "ps1",
  "py",
  "rpm",
  "run",
  "scr",
  "sh",
  "so",
  "vbs",
  "wsf",
];
