<template>
  <div class="fixed bottom-0 left-0 z-50 flex h-full w-full">
    <div
      class="fixed bottom-0 flex h-fit w-full justify-center gap-x-10 bg-background-overlay py-10 px-10"
    >
      <Button variant="outlined" @click="backToSettingsPage">Exit preview</Button>
      <Button
        icon-on-left-side="Check"
        :is-loading="isSaveThemeLoading"
        icon-class="text-white"
        @click="saveCompanyTheme"
        >Apply theme</Button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";

import router from "../../router";
import { usePlatformCompanyThemeStore } from "../../store/platformCompanyTheme";
import Button from "../Button.vue";

const platformCompanyThemeStore = usePlatformCompanyThemeStore();

const isSaveThemeLoading = computed(() => platformCompanyThemeStore.isLoading);

watch(router.currentRoute, () => {
  if (router.currentRoute.value.path !== "/home") {
    platformCompanyThemeStore.disableCompanyThemePreview();
  }
});

function backToSettingsPage() {
  router.push("/platform-settings");
}

async function saveCompanyTheme() {
  await platformCompanyThemeStore.updatePlatformTheme();
  platformCompanyThemeStore.disableCompanyThemePreview();
}
</script>
