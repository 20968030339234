import { isArray } from "lodash";

export function addTranslatedFieldsToObject(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: Record<string, any>,
  fieldsToTranslate?: { field: string; translation: Record<string, string> }[]
) {
  if (!object || !fieldsToTranslate?.length) {
    return;
  }

  Object.entries(object).forEach(([key, value]) => {
    if (value !== null && (isArray(value) || typeof value === "object")) {
      if (isArray(value)) {
        fieldsToTranslate.forEach(({ field, translation }) => {
          if (field === key) {
            Object.assign(object, {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              [`${key}Translated`]: value.map((arrayItem: any) => translation[arrayItem]),
            });
          } else {
            value.forEach((element) => {
              if (typeof element === "object") {
                addTranslatedFieldsToObject(element, fieldsToTranslate);
              }
            });
          }
        });
      } else {
        addTranslatedFieldsToObject(value, fieldsToTranslate);
      }
    } else {
      fieldsToTranslate.forEach(({ field, translation }) => {
        if (field === key) {
          Object.assign(object, { [`${key}Translated`]: value ? translation[value] : null });
        }
      });
    }
  });

  return object;
}
