import * as Sentry from "@sentry/vue";
import { AxiosRequestConfig, Method } from "axios";

import axios from "../axios";
import { useAuthStore } from "../store/auth";
import { convertISOToDateInObject, toast } from "../utils";
import { APIError, AppError, ConflictError, FormError } from "../utils/error";

interface ApiCallRequest {
  method: Method;
  path: string;
  data?: any;
  config?: AxiosRequestConfig;
  token?: string;
}

function handleRequestError(requestError: any): void {
  try {
    const { response } = requestError;
    if (!response) {
      throw new AppError("Check your internet connection and try again");
    }

    switch (response.status) {
      case 401: {
        useAuthStore().cleanSession();

        const message = "Your session has expired. Log in again.";

        toast("error", message);
        throw new APIError({ message }, response.status);
      }
      case 409:
        throw new ConflictError(response.data.message, response.data.duplicateProperty);
      default:
        if (response.data.properties) {
          throw new FormError(response.data.message, response.data.properties);
        }
        throw new APIError(response.data, response.status);
    }
  } catch (handledError) {
    Sentry.captureException(handledError);
    throw handledError;
  }
}

async function apiCall({ method, path, data, config, token }: ApiCallRequest): Promise<any> {
  const accessToken = token ?? useAuthStore().accessToken;

  const requestConfig: AxiosRequestConfig = {
    method,
    url: path,
    data,
    headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
    ...config,
  };

  try {
    const response = await axios(requestConfig);

    if (response.data) {
      convertISOToDateInObject(response, [
        "createdDate",
        "updatedDate",
        "deletedDate",
        "date",
        "contractStartDate",
        "contractEndDate",
      ]);

      return response.data;
    }

    return undefined;
  } catch (error) {
    handleRequestError(error);
  }
}

export async function apiGet(payload: Omit<ApiCallRequest, "method" | "data">): Promise<any> {
  return apiCall({ method: "GET", ...payload });
}

export async function apiPost(payload: Omit<ApiCallRequest, "method">): Promise<any> {
  return apiCall({ method: "POST", ...payload });
}

export async function apiPatch(payload: Omit<ApiCallRequest, "method">): Promise<any> {
  return apiCall({ method: "PATCH", ...payload });
}

export async function apiDelete(payload: Omit<ApiCallRequest, "method">): Promise<any> {
  return apiCall({ method: "DELETE", ...payload });
}
