import * as Sentry from "@sentry/vue";
import { AxiosError, CanceledError } from "axios";

import { toast } from "./toast";

/**
 * Custom application error that can be thrown to interrupt normal execution flow and display an error message to the user using a toast notification.
 *
 * @class
 * @extends Error
 */
export class AppError extends Error {
  constructor(message: string, public readonly info?: any) {
    super(message);
  }
}

export function handleAppError(error: unknown): void {
  Sentry.captureException(error);

  if (error instanceof AppError || error instanceof APIError) {
    toast("error", error.message);
  } else {
    throw error;
  }
}

export class APIError extends Error {
  constructor(public response: { message: string }, public statusCode: number) {
    super(response.message);
  }
}

export class FormError extends Error {
  constructor(message: string, public properties: PropertyError[]) {
    super(message);
  }
}

interface PropertyError {
  property: string;
  errorMessages: string[];
}

export class ConflictError extends Error {
  constructor(message: string, public duplicateProperty: string) {
    super(message);
  }
}

export class ContentError extends Error {
  constructor(
    public readonly contentId: string,
    public readonly type: "upload" | "create",
    error?: unknown
  ) {
    let message = `Failed to ${type} file, try again`;

    if (typeof error === "string") {
      message = error;
    } else if (error instanceof APIError) {
      message = error.message;
    } else if (error instanceof CanceledError) {
      message = "Upload canceled";
    } else if (
      error instanceof AppError ||
      (error instanceof AxiosError && error.code === AxiosError.ERR_NETWORK)
    ) {
      message = "Error: Internet connection lost";
    }

    super(message);
  }
}
