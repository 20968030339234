import { apiGet, apiPatch } from "./_server";
import { BrandingResponseDto, UpdateBrandingDto } from "./models/company";
import { PlanInformation } from "./models/plan";

class CompanyCrud {
  async getLicensesRelation(): Promise<{ licensesQuantity: number; activeUsersQuantity: number }> {
    return apiGet({ path: `company/active_users_licenses_relation` });
  }

  async saveCompanyBranding(data: UpdateBrandingDto): Promise<BrandingResponseDto> {
    return apiPatch({ path: `company/branding`, data });
  }

  async getPlanInformation(): Promise<PlanInformation> {
    return apiGet({ path: "/company/plan_information" });
  }
}

export const companyCrud = new CompanyCrud();
