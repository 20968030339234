import _ from "lodash";

import { CreateContentLocal, CreateCompanyContentDto } from "../api/models/content";
import router from "../router";
import { useUserStore } from "../store/user";

export function getContentPathId(): string | null {
  if (router.currentRoute.value.path.includes("content")) {
    return (router.currentRoute.value.params.id as string) ?? null;
  }

  return null;
}

export function getForbiddenPayloadKeys(payloadKind: "CREATE" | "UPDATE"): string[] {
  const keys = [
    "id",
    "coverImageLocalSrcUrl",
    "fileName",
    "createdDate",
    "createContentStatus",
    "uploadFileStatus",
    "fileExtension",
    "progress",
    "errorMessage",
    "currentVersion",
    "version",
    "updatedDate",
    "disableAutofillingCoverImage",
  ];

  if (payloadKind === "UPDATE") {
    keys.push("user", "updatedDate", "language", "size", "sizeInBytes", "fileUrl");
  }

  return keys;
}

export function getPayload(
  content: CreateContentLocal,
  payloadKind: "CREATE" | "UPDATE"
): CreateCompanyContentDto {
  const payload = _.cloneDeep(content);
  const isAdmin = useUserStore().isActiveUserAdmin;

  if (isAdmin) {
    payload.isConfidential = !!content.isConfidential;
    payload.isRecommended = !!content.isRecommended;
  } else {
    delete (<any>payload)?.isConfidential;
    delete (<any>payload)?.isRecommended;
  }

  if (!payload.foldersIds?.length) {
    delete payload.foldersIds;
  }

  // Remove propriedades não modificadas antes de enviar
  (<(keyof CreateContentLocal)[]>Object.keys(content)).forEach((key) => {
    if (
      getForbiddenPayloadKeys(payloadKind).includes(key) ||
      (payloadKind === "CREATE" && payload[key] === null)
    ) {
      delete payload[key];
    }
  });

  return payload as CreateCompanyContentDto;
}
