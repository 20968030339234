import _ from "lodash";

import { APIError } from "./error";

export function getIcon(icon: string) {
  return new URL(`/src/assets/icons/${icon}`, import.meta.url).href;
}

export function getImage(imageSrc: string) {
  return new URL(`/src/assets/${imageSrc}`, import.meta.url).href;
}

export function isAPIError(error: any): error is APIError {
  return error instanceof APIError;
}

export function joinPaths(...paths: string[]) {
  return paths
    .map((path, index) =>
      index === 0 ? path.replace(/\/$/g, "") : path.replace(/\/$/g, "").replace(/^\//g, "")
    )
    .join("/");
}

export function getObjectChanges(
  firstObject: Record<string, any>,
  secondObject: Record<string, any>
): Record<string, any> {
  const differenceArray = _.differenceWith(
    _.toPairs(secondObject),
    _.toPairs(firstObject),
    _.isEqual
  );

  const changes = {};
  for (const element of differenceArray) {
    Object.assign(changes, { [element[0]]: element[1] });
  }

  return changes;
}

export function isNullOrUndefined(value: any): boolean {
  return [null, undefined].includes(value);
}
