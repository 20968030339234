<template>
  <div class="w-72 rounded-sm bg-white p-4 shadow-md">
    <div class="flex items-center gap-2 truncate">
      <Icon name="Download" class="text-primary" size="1.3rem" />
      <span class="truncate text-base text-body">
        {{ downloadData.fileName }}
      </span>
    </div>

    <div class="h-1 w-full rounded-full bg-tertiary">
      <div
        class="h-full rounded-full bg-primary transition-all"
        :style="{ width: progress * 100 + '%' }"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { DownloadData } from "../store/download";

import Icon from "./Icon.vue";

const props = defineProps<{ downloadData: Readonly<DownloadData> }>();

const progress = computed(() => props.downloadData.progress);
</script>
