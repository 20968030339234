import { defineStore } from "pinia";

import { getProfile } from "../api/me";
import { User, UserRole } from "../api/models/user";
import { flatRoutes } from "../router";
import { DEFAULT_LOGO_URL, DEFAULT_LOGO_ALT } from "../utils";

import { useAuthStore } from "./auth";
import { useOnboardingModeStore } from "./onboardingMode";
import { usePlatformCompanyThemeStore } from "./platformCompanyTheme";

export const useUserStore = defineStore({
  id: "user",
  state: () => {
    return {
      isLoading: false,
      hasNetworkError: false,
      activeUser: null as User | null,
      initialLoggedInRoute: import.meta.env.VITE_PROJECT_TYPE === "user" ? "/home" : "/accounts",
      companyLogoUrl: DEFAULT_LOGO_URL,
      companyLogoAlt: DEFAULT_LOGO_ALT,
    };
  },

  actions: {
    loadInitialLoggedInRoute() {
      // Seleciona a rota de maior prioridade (menor número) que o usuário tem permissão.
      let priority = 1000;

      flatRoutes.forEach(({ path, meta }) => {
        if (
          (meta?.priority || meta?.priority === 0) &&
          (!meta?.permission || this.activeUser?.permissions?.includes(meta.permission)) &&
          meta.priority < priority
        ) {
          priority = meta.priority;
          this.initialLoggedInRoute = path;
        }
      });
    },

    async loadUserData(): Promise<User | null> {
      if (!this.activeUser && useAuthStore().accessToken) {
        try {
          this.isLoading = true;

          this.activeUser = await getProfile();

          this.setCompanyLogo(this.activeUser.company?.logoUrl, this.activeUser.company?.name);

          if (this.activeUser.showOnboarding && !this.isPlatformPersonnel) {
            useOnboardingModeStore().enableOnboardingMode();
          }
          usePlatformCompanyThemeStore().setPlatformThemeByLoggedCompany();

          this.loadInitialLoggedInRoute();
        } catch (_) {
          this.hasNetworkError = true;
        } finally {
          this.isLoading = false;
        }
      }

      return this.activeUser;
    },

    setCompanyLogo(companyLogoUrl?: string | null, name = "Company"): void {
      this.companyLogoUrl = companyLogoUrl ?? DEFAULT_LOGO_URL;
      this.companyLogoAlt = `${name} Logo`;

      if (this.activeUser?.company) {
        this.activeUser.company.logoUrl = this.companyLogoUrl;
      }
    },
  },
  getters: {
    isActiveCompanyUser(state): boolean {
      return (
        !!state.activeUser?.role &&
        [UserRole.ADMINISTRATOR, UserRole.SUPER_ADMINISTRATOR, UserRole.USER].includes(
          state.activeUser.role
        )
      );
    },
    isActiveUserAdmin(state): boolean {
      return (
        !!state.activeUser?.role &&
        [UserRole.ADMINISTRATOR, UserRole.SUPER_ADMINISTRATOR].includes(state.activeUser.role)
      );
    },
    isPlatformPersonnel(state): boolean {
      return (
        !!state.activeUser?.role &&
        [UserRole.PLATFORM_ADMINISTRATOR, UserRole.SUPPORT].includes(state.activeUser.role)
      );
    },
  },
});
