import { defineStore } from "pinia";

import { login, logout, signUp } from "../api/auth";
import { SignUpRequest } from "../api/models/auth";
import { UserRole } from "../api/models/user";
import router from "../router";
import { usePlatformCompanyThemeStore } from "../store/platformCompanyTheme";
import { toast } from "../utils";

import { useOnboardingModeStore } from "./onboardingMode";
import { useUploadContentStore } from "./uploadContent";
import { useUserStore } from "./user";

export const useAuthStore = defineStore({
  id: "auth",
  state: () => {
    return {
      accessToken:
        localStorage.getItem("accessToken") ??
        (sessionStorage.getItem("accessToken") as string | null),
    };
  },
  getters: {
    isUserLoggedIn(state) {
      return !!state.accessToken;
    },
  },
  actions: {
    setToken({ token, rememberMe }: { token: string | null; rememberMe?: boolean }) {
      if (token === null) {
        localStorage.removeItem("accessToken");
        sessionStorage.removeItem("accessToken");
        this.$reset();

        return;
      }

      if (rememberMe) {
        localStorage.setItem("accessToken", token);
      } else {
        sessionStorage.setItem("accessToken", token);
      }

      this.$reset();
    },

    async login({
      email,
      password,
      rememberMe,
    }: {
      email: string;
      password: string;
      rememberMe?: boolean;
    }) {
      const session = await login({ email, password });

      const isPlatformPersonnel = [UserRole.PLATFORM_ADMINISTRATOR, UserRole.SUPPORT].includes(
        session.user.role
      );

      if (
        (import.meta.env.VITE_PROJECT_TYPE === "admin" && !isPlatformPersonnel) ||
        (import.meta.env.VITE_PROJECT_TYPE === "user" && isPlatformPersonnel)
      ) {
        await logout({ headers: { Authorization: `Bearer ${session.token}` } });
        toast("error", "You don't have permission to do this action.");
        throw Error("USER_ROLE_NOT_ALLOWED");
      }

      this.setToken({ token: session.token, rememberMe });
      const userStore = useUserStore();
      if (session.user.company?.logoUrl) {
        userStore.setCompanyLogo(session.user.company.logoUrl, session.user.company.name);
      }

      if (session.user.showOnboarding && !isPlatformPersonnel) {
        useOnboardingModeStore().enableOnboardingMode();
      }

      userStore.activeUser = session.user;
      userStore.loadInitialLoggedInRoute();
    },

    async signup(payload: SignUpRequest) {
      const session = await signUp(payload);

      this.setToken({ token: session.token, rememberMe: false });
      const userStore = useUserStore();
      userStore.activeUser = session.user;
      userStore.loadInitialLoggedInRoute();
    },

    async logout() {
      await logout();
      this.cleanSession();
      usePlatformCompanyThemeStore().resetPlatformThemeColorsToDefault();
    },

    cleanSession() {
      this.setToken({ token: null });
      useUserStore().$reset();
      useUploadContentStore().$reset();

      router.push("/login");
    },
  },
});
