import { defineStore } from "pinia";

import { companyCrud } from "../api/company";
import router from "../router";
import { isAPIError, toast } from "../utils";

import { useAuthStore } from "./auth";
import { useUserStore } from "./user";

interface UpdateThemeDto {
  colors: Record<"primaryHex" | "secondaryHex" | "tertiaryHex", string>;
  themeId: string;
}

type ThemeModeType = "company" | "company-preview";

const defaultThemeColors = {
  PRIMARY_HEX: "#0165d5",
  SECONDARY_HEX: "#7dafe7",
  TERTIARY_HEX: "#ecf2f8",
};

export const usePlatformCompanyThemeStore = defineStore({
  id: "platform_company_theme",
  state: () => {
    return {
      isThemePreviewModeActive: false,
      isLoading: false,
      themeId: "",
    };
  },
  actions: {
    // Configuração das cores da plataforma
    setPlatformThemeColors(
      themeMode: ThemeModeType = "company",
      colors?: Record<"primaryHex" | "secondaryHex" | "tertiaryHex", string | undefined>
    ) {
      const setCSSProperty = (property: string, value: string) => {
        document.body.style.setProperty(property, value);
      };

      setCSSProperty(
        `--${themeMode}-primary`,
        colors?.primaryHex ?? defaultThemeColors.PRIMARY_HEX
      );
      setCSSProperty(
        `--${themeMode}-secondary`,
        colors?.secondaryHex ?? defaultThemeColors.SECONDARY_HEX
      );
      setCSSProperty(
        `--${themeMode}-tertiary`,
        colors?.tertiaryHex ?? defaultThemeColors.TERTIARY_HEX
      );

      document.getElementById("app")?.classList?.add(themeMode);
      localStorage.setItem("theme", themeMode);
    },

    // Configura as cores da plataforma com base na empresa logada
    setPlatformThemeByLoggedCompany() {
      const companyTheme = useUserStore().activeUser?.company?.theme;

      const companyColors = {
        primaryHex: companyTheme?.primaryHex,
        secondaryHex: companyTheme?.secondaryHex,
        tertiaryHex: companyTheme?.tertiaryHex,
      };

      this.themeId = companyTheme?.id || "";

      this.setPlatformThemeColors("company", companyColors);
    },

    // Remove as variáveis de company ou preview e deixa as cores default
    resetPlatformThemeColorsToDefault() {
      document.getElementById("app")?.classList?.remove("company-preview");
      document.getElementById("app")?.classList?.remove("company");

      localStorage.removeItem("theme");
    },

    // Modo de visualização de preview
    enableCompanyPreviewMode(data: UpdateThemeDto) {
      const { colors, themeId } = data;
      this.setPlatformThemeColors("company-preview", colors);

      this.themeId = themeId;
      this.isThemePreviewModeActive = true;
    },

    disableCompanyThemePreview() {
      this.themeId = "";
      this.isThemePreviewModeActive = false;

      document.getElementById("app")?.classList?.remove("company-preview");

      if (useAuthStore().isUserLoggedIn) {
        document.getElementById("app")?.classList?.add("company");
        localStorage.setItem("theme", "company");
      }
    },

    // API
    async updatePlatformTheme() {
      const userStore = useUserStore();
      const themeId = this.themeId;

      try {
        this.isLoading = true;
        const response = await companyCrud.saveCompanyBranding({
          themeId,
        });

        if (userStore.activeUser?.company?.theme?.id) {
          userStore.activeUser.company.theme.id = themeId;
        }

        const colors = {
          primaryHex: response.theme?.primaryHex,
          secondaryHex: response.theme?.secondaryHex,
          tertiaryHex: response.theme?.tertiaryHex,
        };

        this.setPlatformThemeColors("company", colors);

        toast("success", "Theme has updated!");
      } catch (error) {
        if (isAPIError(error)) {
          toast("error", error.response.message);
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  getters: {
    showHomeThemePreviewMenu(): boolean {
      return this.isThemePreviewModeActive && router.currentRoute.value.path === "/home";
    },
  },
});
