<template>
  <div class="fixed left-8 bottom-8 z-50 flex flex-col gap-2">
    <TransitionGroup>
      <DownloadItem v-for="item in downloads" :key="item.id" :download-data="item" />
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { useDownloadStore } from "../store/download";

import DownloadItem from "./DownloadItem.vue";

const downloadStore = useDownloadStore();

const downloads = computed(() => {
  return downloadStore.getDownloads;
});
</script>

<style scoped>
.v-move,
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: translateX(-80px);
}

.v-leave-active {
  position: absolute;
}
</style>
