import { CreateQueryParams, QueryJoin, RequestQueryBuilder } from "@nestjsx/crud-request";

import { addTranslatedFieldsToObject, joinPaths, returnFlatObject } from "../utils";

import { apiDelete, apiGet, apiPatch, apiPost } from "./_server";
import { BasePaginationResponse } from "./models/pagination";

export class Crud<Model, CreateRequest, UpdateRequest> {
  public readonly path: string;
  private readonly relations?: { join: QueryJoin[] };
  private readonly fieldsToTranslate?: { field: string; translation: Record<string, string> }[];

  constructor({
    path,
    relations,
    fieldsToTranslate,
  }: {
    path: string;
    relations?: string[];
    fieldsToTranslate?: { field: string; translation: Record<string, string> }[];
  }) {
    this.path = path;
    this.relations = relations?.length
      ? {
          join: relations.map((relation) => ({ field: relation })),
        }
      : undefined;
    this.fieldsToTranslate = fieldsToTranslate;
  }

  async getMany(
    query?: CreateQueryParams,
    idPath?: string
  ): Promise<BasePaginationResponse<Model>> {
    const queryString = query ? `?json=${JSON.stringify(query)}` : "";
    const path = idPath ? `${this.path}/${idPath}` : this.path;
    let response = await apiGet({ path: path + queryString });
    if (!response.data) {
      response = { page: 1, pageCount: 1, data: response };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response.data.forEach((item: Record<string, any>) => {
      addTranslatedFieldsToObject(item, this.fieldsToTranslate);
    });

    if (this.relations) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response.data = response.data.map((item: Record<string, any>) => returnFlatObject(item));
    }

    return response;
  }

  async getOne(
    id?: string | null,
    query: Record<string, string | number | boolean | Date | null | undefined> = {}
  ): Promise<Model> {
    let queryString = "";
    const extraQueryString = Object.keys(query)
      .filter((key) => query[key] !== undefined)
      .reduce(
        (previousValue, key, index, keys) =>
          `${previousValue}${key}=${query[key]}${index + 1 === keys.length ? "" : "&"}`,
        ""
      );

    if (this.relations) {
      queryString += `?${RequestQueryBuilder.create(this.relations).query()}`;
    }
    if (extraQueryString.length) {
      queryString += `${queryString.length ? "&" : "?"}${extraQueryString}`;
    }

    const path = id ? `${this.path}/${id}` : this.path;

    let response = await apiGet({ path: `${path}${queryString}` });

    if (this.fieldsToTranslate?.length) {
      addTranslatedFieldsToObject(response, this.fieldsToTranslate);
    }

    if (this.relations) {
      response = returnFlatObject(response);
    }

    return response;
  }

  async create(data: CreateRequest, id?: string): Promise<Model> {
    const path = id ? `${this.path}/${id}` : this.path;

    return apiPost({ path, data });
  }

  async update(id: string, data: UpdateRequest): Promise<Model> {
    return apiPatch({ path: joinPaths(this.path, id), data });
  }

  async delete(id: string, data?: unknown): Promise<void> {
    return apiDelete({ path: joinPaths(this.path, id), data });
  }

  async deleteMultiple(data: string[]): Promise<void> {
    return apiDelete({ path: this.path, data });
  }
}
