import { format, parse, parseISO } from "date-fns";
import { isArray } from "lodash";

import { Contract } from "../api/models/contract";

import { isObject } from "./object";

export type NullableDate = Date | string | null | undefined;

export interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}

export function formatToUTCDatetime(value: NullableDate) {
  if (!value) {
    return "-";
  }

  return format(getDateAsUTC(value), "MMM. do, yyyy, hh:mm a");
}

export function formatToDatetime(isoDate: NullableDate) {
  if (!isoDate) {
    return "-";
  }

  return format(parseDateToISO(isoDate), "MMM. do, yyyy, hh:mm a");
}

export function formatToDate(isoDate: NullableDate) {
  if (!isoDate) {
    return "-";
  }

  return format(parseDateToISO(isoDate), "MMM. do, yyyy");
}

export function formatToHours(isoDate: NullableDate) {
  if (!isoDate) {
    return "-";
  }

  return format(parseDateToISO(isoDate), "HH:mm");
}

function parseDateToISO(date: Date | string) {
  return typeof date === "string" ? parseISO(date) : date;
}

function executeFunctionOnAllObjectLayers(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (value: any) => any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: Record<string, any>,
  fields: string[]
) {
  if (!object) {
    return;
  }

  Object.entries(object).forEach(([key, value]) => {
    if (value !== null && (isArray(value) || isObject(value))) {
      if (isArray(value)) {
        value.forEach((element) => {
          if (typeof element === "object") {
            executeFunctionOnAllObjectLayers(callback, element, fields);
          }
        });
      } else {
        executeFunctionOnAllObjectLayers(callback, value, fields);
      }
    } else {
      if (fields.includes(key)) {
        object[key] = callback(value);
      }
    }
  });

  return object;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertDateStringToISOInObject(object: Record<string, any>, fields: string[]) {
  return executeFunctionOnAllObjectLayers(
    (value: string) => {
      if (value === "") {
        return null;
      } else {
        return parse(value, "dd/MM/yyyy", new Date());
      }
    },
    object,
    fields
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertISOToDateInObject(object: Record<string, any>, fields: string[]) {
  return executeFunctionOnAllObjectLayers(
    (value: string | null) => {
      if (value !== null) {
        return parseISO(value);
      }
    },
    object,
    fields
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertISOToDateStringInObject(object: Record<string, any>, fields: string[]) {
  return executeFunctionOnAllObjectLayers(
    (value: string | null) => {
      if (value === null) {
        return "";
      } else {
        return format(parseISO(value), "dd/MM/yyyy");
      }
    },
    object,
    fields
  );
}

export function setContractPayloadLocalDates<
  T extends Pick<Partial<Contract>, "startDate" | "endDate">
>(payload: T): void {
  payload.startDate?.setHours(0, 0, 0, 0);
  payload.endDate?.setHours(23, 0, 0, 0);
}

export function isTimeStringValid(
  value?: string | null,
  { isTwelveHourTime, isFullTime }: { isTwelveHourTime?: true; isFullTime?: true } = {}
): boolean {
  const validLength = isFullTime ? 8 : 5;

  if (!value || value.length !== validLength) {
    return false;
  }

  const [hours, minutes, seconds] = value.split(":").map(Number);

  const isHourValid = hours < (isTwelveHourTime ? 13 : 24);
  const isMinuteValid = minutes < 60;
  const isSecondValid = isFullTime ? seconds < 60 : true;

  return isHourValid && isMinuteValid && isSecondValid;
}

export function to12HourFormat(hours: number): string {
  let _hours = hours;

  if (_hours > 12) {
    _hours -= 12;
  } else if (_hours === 0) {
    _hours = 12;
  }

  return formatTwoDigitNumber(_hours);
}

export function formatTwoDigitNumber(value: number): string {
  return `${value < 10 ? "0" : ""}${value}`;
}

export function getDateAsUTC(value: Date | string) {
  const date = new Date(value);

  date.setFullYear(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  date.setHours(date.getUTCHours(), date.getUTCMinutes(), 0, 0);

  return date;
}

export function getGMTStringByOffsetMinutes(offsetMinutes: number): string {
  const hours = Math.trunc(offsetMinutes / 60);
  const minutes = Math.abs(offsetMinutes % 60);
  const mathSign = hours >= 0 ? "+" : "";
  const minutesStr = minutes ? `:${minutes}` : "";

  return `GMT${mathSign}${hours}${minutesStr}`;
}

export function formatUTCDateWithTimezone(date: Date, offsetMinutes: number): string {
  return `${formatToUTCDatetime(date)} (${getGMTStringByOffsetMinutes(offsetMinutes)})`;
}
