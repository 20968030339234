import maska from "maska";
import "material-icons/iconfont/material-icons.css";
import { createPinia } from "pinia";
import "v-calendar/dist/style.css";
import { createApp } from "vue";
import { dragscrollNext } from "vue-dragscroll";
import toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueApexCharts from "vue3-apexcharts";

import App from "./App.vue";
import tooltipDirective from "./customDirectives/tooltip";
import { initializeSentry } from "./plugins/sentry";
import router from "./router";
import "./styles/global.postcss";
import { handleAppError } from "./utils/error";

// Mock Service Worker disabled becasuse it was preventing large file uploads
// if (import.meta.env.VITE_ENVIRONMENT?.toString().toLowerCase() === "dev") {
//   worker.start({ onUnhandledRequest: "bypass" });
// }

const app = createApp(App)
  .use(router)
  .use(createPinia())
  .use(maska)
  .use(toast)
  .directive("dragscroll", dragscrollNext)
  .directive("tooltip", tooltipDirective)
  .use(VueApexCharts);

initializeSentry(app, router);

app.config.errorHandler = handleAppError;

app.mount("#app");
