import { AxiosProgressEvent } from "axios";
import { defineStore } from "pinia";
import { v4 as uuid } from "uuid";

import { downloadFileFromUrl } from "../utils";

export class DownloadData {
  constructor(
    public readonly fileUrl: string,
    public readonly fileName?: string,
    public readonly onDownloadFinished?: () => void
  ) {
    downloadFileFromUrl(fileUrl, fileName, (event) => this._listenToProgress(event));
  }
  readonly id = uuid();

  progress = 0;

  private _listenToProgress({ loaded, total = 0 }: AxiosProgressEvent): void {
    const downloadStore = useDownloadStore();

    downloadStore.updateDownloadProgress(this.id, loaded / total);

    if (this.progress === 1) {
      setTimeout(() => downloadStore.removeDownload(this.id), 10000);
      this.onDownloadFinished?.();
    }
  }
}

export const useDownloadStore = defineStore({
  id: "download",
  state: (): { downloads: DownloadData[] } => ({
    downloads: [],
  }),
  actions: {
    addDownload({ fileUrl, fileName, onDownloadFinished }: AddDownloadOptions) {
      this.downloads.push(new DownloadData(fileUrl, fileName, onDownloadFinished));
    },
    removeDownload(downloadId: string) {
      this.downloads = this.downloads.filter((download) => download.id !== downloadId);
    },
    updateDownloadProgress(downloadId: string, progress: number) {
      const download = this.downloads.find((downloadData) => downloadData.id === downloadId);
      if (download) {
        download.progress = progress;
      }
    },
  },

  getters: {
    getDownloads(): Readonly<DownloadData>[] {
      return this.downloads;
    },
  },
});

export interface AddDownloadOptions {
  fileUrl: string;
  fileName?: string;
  onDownloadFinished?: () => void;
}
