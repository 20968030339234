import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

NProgress.configure({ showSpinner: false });

export function startProgressBar() {
  NProgress.start();
}

export function stopProgressBar() {
  if (NProgress.isStarted()) {
    NProgress.done();
  }
}

export default NProgress;
