import { CreateQueryParams } from "@nestjsx/crud-request";

import { joinPaths } from "../utils";

import { Crud } from "./_crud";
import { apiPatch, apiPost } from "./_server";
import { UpdateContentFileDto, Content } from "./models/content";
import { ContentFileVersion } from "./models/contentFileVersion";
import { BasePaginationResponse } from "./models/pagination";

class ContentFileVersionCrud extends Crud<ContentFileVersion, any, any> {
  override async getMany(
    query?: CreateQueryParams,
    contentId?: string
  ): Promise<BasePaginationResponse<ContentFileVersion>> {
    return super.getMany(query, `content/${contentId}`);
  }

  async updateContentFile(contentId: string, data: UpdateContentFileDto): Promise<Content> {
    return apiPost({ path: joinPaths(this.path, "content", contentId), data });
  }

  async deleteContentFile(id: string): Promise<void> {
    return apiPatch({ path: joinPaths(this.path, id, "delete_file") });
  }
}

export const contentFileVersionCrud = new ContentFileVersionCrud({
  path: "content_file_version",
  relations: ["author"],
});
