import { defineStore } from "pinia";

import { turnOffOnboarding } from "../api/me";
import { UserRole } from "../api/models/user";

import { useUserStore } from "./user";

type Step = {
  allowedRoles?: UserRole[];
  title: string;
  iconName: string; // IconName
  stepIdentifierEnum: OnboardingStepEnum;
  description: string;
  customPositionClass: string;
  circleYInVH?: number;
  circleXInVW?: number;
  circleWidthInRem: string;
  circleHeightInRem: string;
};

export enum OnboardingStepEnum {
  MODAL_ONBOARDING_UPLOAD = "MODAL_ONBOARDING_UPLOAD",
  MODAL_ONBOARDING_LIBRARIES = "MODAL_ONBOARDING_LIBRARIES",
  MODAL_ONBOARDING_SHARE = "MODAL_ONBOARDING_SHARE",
  MODAL_ONBOARDING_RECOMMENDED = "MODAL_ONBOARDING_RECOMMENDED",
  MODAL_ONBOARDING_PEOPLE = "MODAL_ONBOARDING_PEOPLE",
  MODAL_ONBOARDING_SETTINGS = "MODAL_ONBOARDING_SETTINGS",
  MODAL_ONBOARDING_RATINGS = "MODAL_ONBOARDING_RATINGS",
  MODAL_ONBOARDING_HELP = "MODAL_ONBOARDING_HELP",
}

interface CSSStyleDeclaration {
  webkitMaskComposite?: string;
  maskComposite?: string;
}

export const useOnboardingModeStore = defineStore({
  id: "onboarding_mode",
  state: () => ({
    isOnboardingModeActive: false,
    currentStepIndex: -1,
    steps: [] as Step[],
  }),

  actions: {
    enableOnboardingMode() {
      const windowWidth = window.innerWidth;
      if (windowWidth < 1024) {
        return;
      }
      this.currentStepIndex = -1;
      this.isOnboardingModeActive = true;
      document.body.style.overflow = "hidden"; // desativa o scroll da tela toda
    },

    disableOnboardingMode() {
      document.body.style.overflow = "auto"; // desativa o scroll da tela toda

      this.currentStepIndex = -1;
      this.isOnboardingModeActive = false;
      window.scrollTo({ top: 0, behavior: "smooth" });
      const userStore = useUserStore();

      if (userStore.activeUser) {
        if (userStore.activeUser.showOnboarding) {
          turnOffOnboarding();

          userStore.activeUser.showOnboarding = false;
        }
      }
    },

    nextOnboardingStep() {
      if (this.currentStepIndex >= this.getSteps.length - 1) {
        return;
      }

      const onboardingStepsCard = document.getElementById("onboarding-steps-card");
      const onboardingStepsBackground = document.getElementById("onboarding-steps-background");

      if (onboardingStepsCard && onboardingStepsBackground) {
        onboardingStepsCard.style.opacity = "0";
        onboardingStepsBackground.style.mask = "none";
        onboardingStepsBackground.style.webkitMask = "none";
      }

      this.setModalStepScrollPosition(this.currentStepIndex + 1);
      setTimeout(() => {
        this.currentStepIndex = this.currentStepIndex + 1;
      }, 200); // aguarda atualizar a nova posição do modal para depois trocar a posição
    },

    setModalStepScrollPosition(stepIndex: number) {
      const stepToChange = this.getSteps[stepIndex];
      const currentStepIdentifierId = stepToChange.stepIdentifierEnum;
      const onboardingStepsCard = document.getElementById("onboarding-steps-card");
      const onboardingStepsModal = document.getElementById("onboarding-steps-modal");
      const onboardingStepsBackground = document.getElementById("onboarding-steps-background");

      const componentToShowOnboardingModal = document.getElementById(currentStepIdentifierId);

      if (componentToShowOnboardingModal && onboardingStepsModal && onboardingStepsBackground) {
        this.scrollToOnboardingStep(componentToShowOnboardingModal);

        // aguarda o scroll da tela para depois setar a nova posição dos elementos
        setTimeout(() => {
          const coordinates = componentToShowOnboardingModal.getBoundingClientRect();

          const topCoordinates = parseFloat(
            (coordinates.top > 99 ? coordinates.top : 0).toString()
          );

          const positionTopInVH = parseFloat(
            (((topCoordinates / window.innerHeight) * 100) % 100).toString().padStart(2, "0")
          );
          const positionLeftInVW = (coordinates.left / window.innerWidth) * 100;

          onboardingStepsModal.style.left = `${positionLeftInVW}vw`;
          onboardingStepsModal.style.top = `${positionTopInVH}vh`;

          this.setStepCirclePosition(
            positionLeftInVW,
            positionTopInVH,
            stepToChange,
            onboardingStepsBackground
          );

          if (onboardingStepsCard) {
            onboardingStepsCard.style.opacity = "1";
          }
        }, 800);
      } else {
        console.error(`Element with id "${currentStepIdentifierId}" not found`);
      }
    },

    setStepCirclePosition(
      positionLeftInVW: number,
      positionTopInVH: number,
      stepToChange: Step,
      onboardingStepsBackground: HTMLElement
    ) {
      const circleMask = `radial-gradient(farthest-side, #000 98%, #0000) ${
        positionLeftInVW + (stepToChange.circleXInVW || 0)
      }vw ${positionTopInVH + (stepToChange.circleYInVH || 0)}vh / ${
        stepToChange.circleWidthInRem
      } ${stepToChange.circleHeightInRem} no-repeat, linear-gradient(#000 0 0)`;

      const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

      if (isChrome && "webkitMask" in onboardingStepsBackground.style) {
        // Navegador Chrome
        onboardingStepsBackground.style.webkitMask = circleMask;
        onboardingStepsBackground.style.webkitMaskComposite = "destination-out";
      } else {
        // Outros navegadores modernos
        onboardingStepsBackground.style.mask = circleMask;
        (onboardingStepsBackground.style as CSSStyleDeclaration).maskComposite = "exclude";
      }
    },

    scrollToOnboardingStep(componentToShowOnboardingElement: HTMLElement) {
      const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
      const rect = componentToShowOnboardingElement.getBoundingClientRect();
      const offset = rect.top - 10 * fontSize;
      window.scrollTo({ top: offset, behavior: "smooth" });
    },
  },
  getters: {
    activeStep(): Step {
      return this.steps[this.currentStepIndex];
    },

    getSteps(): Step[] {
      const userRole = useUserStore().activeUser?.role;

      const formattedSteps: Step[] = [
        {
          title: "Uploads",
          iconName: "Upload",
          stepIdentifierEnum: OnboardingStepEnum.MODAL_ONBOARDING_UPLOAD,
          customPositionClass: "absolute right-[1.5rem] -top-[2rem]",
          circleXInVW: -1,
          circleYInVH: -6,
          circleWidthInRem: "13rem",
          circleHeightInRem: "8rem",
          description:
            userRole === UserRole.USER
              ? "Use the “Uploads” button to start uploading content to your Space. You can also find your recent uploads here!"
              : "Use the “Uploads” button to start uploading content to your users. You can also find your recent uploads here!",
        },
        {
          title: "Libraries",
          iconName: "CompanyFolder",
          stepIdentifierEnum: OnboardingStepEnum.MODAL_ONBOARDING_LIBRARIES,
          customPositionClass: "absolute top-[2rem] right-8",
          circleXInVW: -1,
          circleYInVH: 2,
          circleWidthInRem: "8rem",
          circleHeightInRem: "4rem",
          description:
            userRole === UserRole.USER
              ? "Use libraries to organize your content for yourself and clients. Use your Space to access and view your personal content, and libraries to view what your company has made available for you."
              : "Use libraries to organize content for yourself and users. Your Space can only be seen by you, but libraries can be accessed by all user groups you assign to them.",
        },
        {
          title: "Sharing board",
          iconName: "Reply",
          stepIdentifierEnum: OnboardingStepEnum.MODAL_ONBOARDING_SHARE,
          customPositionClass: "absolute right-[1.5rem] -top-[2rem]",
          circleXInVW: -1,
          circleYInVH: -4,
          circleWidthInRem: "13rem",
          circleHeightInRem: "7rem",
          description:
            "Use the “Sharing board” to manage content you want to distribute to people outside the platform, such as your customers. Content that is not tagged as “Confidential” can be added here through the “Actions” button.",
        },
        {
          allowedRoles: [UserRole.USER],
          title: "Recommended content",
          iconName: "Recommended",
          stepIdentifierEnum: OnboardingStepEnum.MODAL_ONBOARDING_RECOMMENDED,
          customPositionClass: "absolute left-[24rem] -top-2",
          circleYInVH: -5,
          circleWidthInRem: "21rem",
          circleHeightInRem: "8rem",
          description:
            "Recommended content is defined by the admins in your platform. They are usually content that should be readily available to you on your day by day activities. You can easily find them here in the homepage!",
        },
        {
          allowedRoles: [UserRole.ADMINISTRATOR, UserRole.SUPER_ADMINISTRATOR],
          title: "People",
          iconName: "Person",
          stepIdentifierEnum: OnboardingStepEnum.MODAL_ONBOARDING_PEOPLE,
          customPositionClass: "absolute top-[2rem] right-8",
          circleXInVW: -1,
          circleYInVH: 2,
          circleWidthInRem: "6rem",
          circleHeightInRem: "4rem",
          description:
            "To start adding users and managing them through user groups check the “People” page on the top menu!",
        },
        {
          title: "Account settings",
          iconName: "Settings",
          stepIdentifierEnum: OnboardingStepEnum.MODAL_ONBOARDING_SETTINGS,
          customPositionClass: "absolute -right-[4rem] top-4",
          circleXInVW: 6,
          circleWidthInRem: "16rem",
          circleHeightInRem: "9rem",
          description:
            "You can access your “Account settings” through the top right menu! Here you can edit your profile picture, account password, and contact information.",
        },
        {
          allowedRoles: [UserRole.ADMINISTRATOR, UserRole.SUPER_ADMINISTRATOR],
          title: "Ratings",
          iconName: "Star",
          stepIdentifierEnum: OnboardingStepEnum.MODAL_ONBOARDING_RATINGS,
          customPositionClass: "absolute left-[18rem] -top-8",
          circleYInVH: -4,
          circleWidthInRem: "15rem",
          circleHeightInRem: "7rem",
          description:
            "Users are able to rate content created by admins and leave feedback. This will help your team of admins to better understand the reception of the content distributed in Kala, and improve future versions!",
        },
        {
          title: "Help page",
          iconName: "QuestionMark",
          stepIdentifierEnum: OnboardingStepEnum.MODAL_ONBOARDING_HELP,
          customPositionClass: "fixed right-28 bottom-2",
          circleXInVW: -1,
          circleYInVH: -2,
          circleWidthInRem: "4.5rem",
          circleHeightInRem: "4.5rem",
          description:
            userRole === UserRole.USER
              ? "The button on the bottom right leads you to the Help page, where you can find further information on how to use Kala. For additional questions, contact your admin!"
              : "The button on the bottom right leads you to the Help page, where you can find further information on how to use Kala. For additional questions, feel free to contact Kala!",
        },
      ];

      this.steps = formattedSteps.filter(
        (formattedStep) =>
          document.getElementById(formattedStep.stepIdentifierEnum) &&
          (!formattedStep.allowedRoles ||
            (userRole && formattedStep.allowedRoles.includes(userRole)))
      );

      return this.steps;
    },
  },
});
