import * as Sentry from "@sentry/vue";
import { App } from "vue";
import { Router } from "vue-router";

import { useUserStore } from "../store/user";

export function initializeSentry(app: App, router: Router) {
  const {
    VITE_SENTRY_DSN,
    VITE_ENVIRONMENT,
    VITE_API_BASE_URL,
    VITE_SENTRY_TRACES_SAMPLE_RATE,
    VITE_SENTRY_REPLAYS_SAMPLE_RATE,
    VITE_SENTRY_ERROR_SAMPLE_RATE,
  } = import.meta.env;

  if (!VITE_SENTRY_DSN || VITE_ENVIRONMENT === "local") {
    return;
  }

  Sentry.init({
    app,
    dsn: VITE_SENTRY_DSN,
    tracePropagationTargets: [VITE_API_BASE_URL],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        _experiments: {
          // Create transactions for interactions besides pageload and navigation
          enableInteractions: true,
        },
      }),
      new Sentry.Replay(),
    ],
    environment: VITE_ENVIRONMENT,
    beforeSend: attachUserToEvent,
    beforeSendTransaction: attachUserToEvent,
    tracesSampleRate: VITE_SENTRY_TRACES_SAMPLE_RATE ? Number(VITE_SENTRY_TRACES_SAMPLE_RATE) : 1.0,
    replaysSessionSampleRate: VITE_SENTRY_REPLAYS_SAMPLE_RATE
      ? Number(VITE_SENTRY_REPLAYS_SAMPLE_RATE)
      : 0.1,
    replaysOnErrorSampleRate: VITE_SENTRY_ERROR_SAMPLE_RATE
      ? Number(VITE_SENTRY_ERROR_SAMPLE_RATE)
      : 1.0,
  });
}

function attachUserToEvent(event: Sentry.Event): Sentry.Event {
  const user = useUserStore().activeUser;

  event.user = {
    id: user?.id,
    email: user?.email,
  };

  return event;
}
