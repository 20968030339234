import dynamicRoutes from "@dynamicRoutes";
import {
  createRouter,
  createWebHistory,
  RouteLocationRaw,
  Router,
  RouteRecordRaw,
} from "vue-router";

import { useAuthStore } from "../store/auth";
import { useUploadContentStore } from "../store/uploadContent";
import { useUserStore } from "../store/user";
import { startProgressBar, stopProgressBar } from "../utils/ngprogress";

let loadingTimeout: NodeJS.Timeout;

const sharedRoutes: RouteRecordRaw[] = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/request-password-reset",
    name: "Request password reset",
    component: () => import("../views/RequestPasswordReset.vue"),
  },
  {
    path: "/reset-password",
    name: "Reset password",
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/first-access",
    name: "First access",
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/share-public/:id",
    name: "Share Board",
    component: () => import("../views/Share.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Page not found",
    component: () => import("../views/Error404.vue"),
  },
];

const routes = [...sharedRoutes, ...dynamicRoutes];

export interface UpdateQueryParamsOptions {
  replaceRoute?: boolean;
  resetParams?: boolean;
}

interface CustomRouter extends Router {
  updateQueryParams: (
    parameters: Record<string, any>,
    options?: UpdateQueryParamsOptions
  ) => Promise<void>;
  removeQueryParams: (keys: string[]) => Promise<void>;
  parsedQuery: Record<string, any>;
}

const router: CustomRouter = {
  ...createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from) {
      if (to.hash) {
        const navbarHeight = document.getElementById("navbar")?.clientHeight ?? 0;

        return {
          el: to.hash,
          top: navbarHeight,
          behavior: "smooth",
        };
      }

      if (to.path !== from.path) {
        return { top: 0, behavior: "smooth" };
      }

      return false;
    },
  }),
  async updateQueryParams(parameters, { replaceRoute, resetParams } = {}) {
    const newQuery: RouteLocationRaw = {
      query: {
        ...(resetParams ? {} : this.currentRoute.value.query),
        ...Object.entries(parameters).reduce<Record<string, any>>((accumulator, [key, value]) => {
          accumulator[key] = JSON.stringify(value);

          return accumulator;
        }, {}),
      },
    };

    if (replaceRoute) {
      await this.replace(newQuery);
    } else {
      await this.push(newQuery);
    }
  },
  async removeQueryParams(keys) {
    const query = this.parsedQuery;

    keys.forEach((key) => {
      delete query[key];
    });

    await this.updateQueryParams(query, { resetParams: true });
  },
  get parsedQuery() {
    return Object.entries(this.currentRoute.value.query).reduce<Record<string, any>>(
      (accumulator, [key, value]) => {
        if (value) {
          try {
            const parsedValue = JSON.parse(value as string);
            // Always cast "keyword" to string to avoid numbers being parsed as numbers
            accumulator[key] = key === "keyword" ? `${parsedValue}` : parsedValue;
          } catch {
            accumulator[key] = value;
          }
        }

        return accumulator;
      },
      {}
    );
  },
};

function generateFlatRoutes(rawRoutes: RouteRecordRaw[], parentPath?: string) {
  const _flatRoutes: RouteRecordRaw[] = [];

  rawRoutes.forEach((route) => {
    if (route.children) {
      const childrensParentPath = parentPath ? `${parentPath}/${route.path}` : route.path;

      _flatRoutes.push(...generateFlatRoutes(route.children, childrensParentPath));
    } else {
      if (parentPath) {
        route.path = parentPath + (route.path ? `/${route.path}` : "");

        route.path = route.path.replace(new RegExp("/{2,}", "gm"), "/");
      }
      _flatRoutes.push(route);
    }
  });

  return _flatRoutes;
}

export const flatRoutes = generateFlatRoutes(routes);

router.beforeEach(async ({ name, meta, query, path, params }) => {
  startNProgressDelayed();

  document.title = name ? `Kala | ${name.toString()}` : "Kala";

  if (name === "Page not found") {
    return;
  }

  const authStore = useAuthStore();
  const userStore = useUserStore();

  const token = query?.token;

  //Redirecionamento de platform admin para acessar como super admin na plataforma
  if (import.meta.env.VITE_PROJECT_TYPE === "user" && token) {
    authStore.setToken({ token: token.toString() });
  }
  if (path.includes("share-public") && authStore.isUserLoggedIn) {
    return `/share/${params.id}`;
  }
  if (path.includes("/share/") && !authStore.isUserLoggedIn) {
    return `/share-public/${params.id}`;
  }
  if (meta.requiresAuth && !authStore.isUserLoggedIn) {
    return "/login";
  }

  const activeUser = await userStore.loadUserData();
  if (authStore.isUserLoggedIn) {
    await useUploadContentStore().loadRecentUploads();
  }

  const isUserRoleInvalid =
    activeUser && meta.allowedUserRoles && !meta.allowedUserRoles.includes(activeUser.role);

  if ((!meta.requiresAuth && authStore.isUserLoggedIn) || isUserRoleInvalid) {
    return userStore.initialLoggedInRoute;
  }
});

router.afterEach(() => {
  clearTimeout(loadingTimeout);
  stopProgressBar();
});

router.onError(() => {
  clearTimeout(loadingTimeout);
  stopProgressBar();
});

function startNProgressDelayed() {
  // barra de progresso no carregamento das rotas
  loadingTimeout = setTimeout(() => {
    startProgressBar();
  }, 2000);
}

export default router;
